import { Injectable } from '@angular/core';
import {
  EEndpointMethod,
  RESTGeneratorService,
} from './rest-generator.service';

@Injectable({
  providedIn: 'root',
})
export class ModuleColourService {
  restFuncs = this.restGenS.generateRESTFunctions({
    saveColourCode: { route: `colour/codes`, method: EEndpointMethod.post },
    searchColourCodes: {
      route: `colour/codes/search`,
      method: EEndpointMethod.get,
    },
  });
  constructor(protected restGenS: RESTGeneratorService) {
 
  }
}
