import { Component, inject, signal, viewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ChatHelpDeskComponent } from '../../../evolutics-shared-lib/src/lib/Shared/components/page-icons/chat-help-desk/chat-help-desk.component';
import { lastValueFrom } from 'rxjs';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ToastNotificationsComponent } from '@serene-dev/toast-notifications';
import { UserPreviewModalComponent } from '@Shared/components/user-preview-modal/user-preview-modal.component';
import { LoaderComponent, Config } from 'ets-fe-ng-sdk';
import { SharedAppComponent } from 'projects/evolutics-shared-lib/src/lib/shared-app.component';
import { WebUserAuthenticationService } from '@Authentication/web-user/web-user-authentication.service';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    MatProgressBar,
    LoaderComponent,
    RouterOutlet,
    ChatHelpDeskComponent,
    UserPreviewModalComponent,
    ToastNotificationsComponent,
  ],
})
export class AppComponent extends SharedAppComponent {
  readonly UserPreviewModalComponentRef = viewChild(UserPreviewModalComponent);
  readonly allowProfilePreview = signal(false);
  readonly webAuthS = inject(WebUserAuthenticationService);
  async ngOnInit() {
    // debugger
    // if (this.webAuthS.isLoggedin) {
    //   this.authService.getAllFromLocal();
    //   lastValueFrom(this.transGlossary.getGlossary());
    //   this.authService.getAllFromOnline();
    // }
    super.ngOnInit();
    ['createdBy', 'updatedBy'].forEach((fieldName) => {
      this.uS.tableService.generalColumnPropMap[fieldName] = {
        onHoverHandler: {
          action: (row, event) => {
            if (this.allowProfilePreview())
              this.UserPreviewModalComponentRef()?.open(event as PointerEvent, {
                userName: row?.[fieldName],
              });
            else {
              this.allowProfilePreview.set(true);
              setTimeout(() => {
                this.UserPreviewModalComponentRef()?.open(event as PointerEvent, {
                  userName: row?.[fieldName],
                });
              }, 600);
            }
          },
          delay: 1000,
        },
      };
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
}
