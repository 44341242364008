import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RESTGeneratorService {
  constructor(public apiS: ApiService) {}
  generateRESTFunctions<
    T extends { [key in keyof T]: IEndpoint } = { [x: string]: IEndpoint }
  >(endpoints: T, baseURL = environment.apiBaseUrl + '/rest/') {
    const res2: { [key in keyof T]: IRequestFunc } = {} as any;
    for (const key in endpoints) {
      if (Object.prototype.hasOwnProperty.call(endpoints, key)) {
        const element: IEndpoint = endpoints[key];
        switch (element.method) {
          case EEndpointMethod.get:
            res2[key] = () =>
              this.apiS.get(
                (baseURL || '') + element.route,
                element.queryObject
              ); 
            break;
          case EEndpointMethod.post:
            res2[key] = () =>
              this.apiS.post(
                (baseURL || '') + element.route,
                element.body
              ); 
            break;
          case EEndpointMethod.delete:
            res2[key] = () =>
              this.apiS.delete(
                (baseURL || '') + element.route,
              ); 
            break;

          default:
            break;
        }
      }
    }

    return res2;
  }
}
export interface IEndpoint {
  method: EEndpointMethod;
  route: string;
  queryObject?: { [x: string]: string | number | boolean };
  body?: any;
  func?: IRequestFunc;
}
export enum EEndpointMethod {
  post = 'post',
  get = 'get',
  put = 'put',
  patch = 'patch',
  delete = 'delete',
}

type IRequestFunc = (config?: { queryObject; body }) => Observable<any>;
