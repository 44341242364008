import { enableProdMode } from '@angular/core';
import '@Shared/prototypes/prototypes';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { routes } from './app/app.routes';
import { sharedAppConfig } from 'projects/evolutics-shared-lib/src/lib/shared-app.config';
import { EAccessType } from '@Shared/enums/accessType.enum';
import { MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';

if (environment.production) enableProdMode();

function setVariables() {
  environment.sentryKey =
    'https://a2d12a1a4f56e7b7d783d7f62a4a8b83@o4507594374250496.ingest.de.sentry.io/4507702402089040';
  environment.accessType = EAccessType.reinsurance;
  return environment;
}
bootstrapApplication(
  AppComponent,
  sharedAppConfig(routes, setVariables(), [
    {
      provide: MAT_TABS_CONFIG,
      useValue: <MatTabsConfig>{ dynamicHeight: true, stretchTabs: false },
    },
  ]),
).catch((err) => console.error(err));
