import { Routes } from '@angular/router';
import { ReinsDashboardLayoutComponent } from './reinsurance-shared/components/reins-dashboard-layout/reins-dashboard-layout.component';
import { authenticationGuard } from './guards/authentication.guard';
import { appRoutes } from '../../../evolutics-client-ui/src/app/configs/app-routes-configs/app-routes.config';

export const routes: Routes = [
  // {
  //   path: 'auth',
  //   children: authenticationRoutes,
  // },

  {
    path: appRoutes.auth._,
    loadChildren: () =>
      import('../../../evolutics-shared-lib/src/lib/Authentication/web-user/web-user-auth.routing'),
  },
  {
    path: 'login',
    redirectTo: appRoutes.auth._ + '/login',
  },
  {
    path: '',
    component: ReinsDashboardLayoutComponent,
    canActivateChild: [authenticationGuard],
    children: [
      {
        path: 'home',
        data: { title: 'Dashboard' },
        loadComponent: () =>
          import('./reinsurance-home/reinsurance-home.component').then((c) => c.ReinsuranceHomeComponent),
      },
      {
        path: 'workflow-desk',
        loadChildren: () =>
          import('projects/evolutics-client-ui/src/app/Life/Workflow/workflow.module').then(
            (m) => m.WorkflowModule,
          ),
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
    ],
  },
];
