import { Injectable } from '@angular/core';
import { AppSaverService } from '@Services/cache/saver.service';
import { ModuleColourService } from '@Services/module-colour.service';
import { IModuleTheme } from './module-theme-setup.model';

@Injectable({
  providedIn: 'root',
})
export class ModuleThemeSetupService extends AppSaverService {
  private moduleThemes: IModuleTheme[];
  constructor(public mCS: ModuleColourService) {
    super('moduleThemes', 'moduleTheme');
    // debugger
    this.applyModuleTheme();
  }

  /**
   * Apply module themes setup on the web app
   * @param moduleThemes The module themes setup
   */
  applyModuleTheme(moduleThemes?: IModuleTheme[]) {
    // if (moduleThemes?.length) {
    //   this.moduleThemes = moduleThemes;
    //   this.saveToLocal();
    // }
    // // debugger;
    // this.mCS.restFuncs.searchColourCodes().subscribe(r=>{

    // })
    // if (this.moduleThemes)
    //   for (const item of this.moduleThemes) {
    //     const map = Config.systemMetadataMap.get(item.system);
    //     map.colourCode = item.colourCode || map.colourCode;
    //   }
  }
}
