// import { Inject, Injectable, InjectionToken } from '@angular/core';
// import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
// import { Observable } from 'rxjs';
// import { timeout } from 'rxjs/operators';
// import { environment } from '@environments/environment';
// import { Config } from '@configs/index.config';

// export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

// @Injectable({
//   providedIn: 'root',
// })
// export class RequestTimeoutInterceptorService {
//   constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {}

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     // if (+req.headers.get('timeout') || environment.requestTimeout)
//     //   console.log(
//     //     'timeout',
//     //     +req.headers.get('timeout') ,
//     //       environment.requestTimeout ,
//     //       this.defaultTimeout
//     //   );
//     // console.log(
//     //   +req.headers.get('timeout') ,
//     //     environment.requestTimeout ,
//     //     this.defaultTimeout
//     // );
//     const tout = +req.headers.get('timeout') || environment.requestTimeout || this.defaultTimeout;
//     // console.log('timeout period',tout);
//     return next.handle(req).pipe(timeout(1000));
//   }
// }

// export {
//   RequestTimeoutInterceptorService,
//   DEFAULT_TIMEOUT,
// } from 'ets-fe-ng-sdk';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Config } from '../../configs/index.config';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: 'root',
})
export class RequestTimeoutInterceptorService {
  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (+req.headers.get('timeout') || environment.requestTimeout)
    //   console.log(
    //     'timeout',
    //     +req.headers.get('timeout') ,
    //       environment.requestTimeout ,
    //       this.defaultTimeout
    //   );
    // console.log(
    //   +req.headers.get('timeout') ,
    //     environment.requestTimeout ,
    //     this.defaultTimeout
    // );

    // console.log('timeout period',tout);
    const min = 5;
    const endMS = environment.production
      ? min * 60000
      : new Date(Config.TimeStampDay * 365 + Date.now()).getTime() - Date.now();
    // console.log(endMS);
    return next.handle(req).pipe(
      timeout(endMS),
      catchError((e) => {
        if (e.name == 'TimeoutError') throw e.message;
        throw e;
      })
    );
  }
}
