import { IMenuItem } from '@Shared/models/IMenuItem'; 
import { PageChildren } from '../../../../evolutics-client-ui/src/app/configs/menu-configs/children.config';

export const reinsMenu: IMenuItem[] = [
  { label: 'Dashboard', link: `/home`, icon: 'fa fa-home' },
  {
    label: 'Workflow Desk',
    icon: 'fas fa-tasks ',
    link: '/workflow-desk',
    subs: [
      {
        label: 'Overview',
        link: '/workflow-desk/overview',
        id: 'MO29',
      },
      {
        label: 'Find Workflow Task',
        link: '/workflow-desk/find',
        id: 'MFWT30',
      },
      {
        label: 'Find Workflow Schedule',
        link: '/workflow-desk/schedule/find',
        id: 'MFWS31M',
      },
      {
        label: 'Create New',
        link: '/workflow-desk/create',
        subs: [
          {
            label: 'Task',
            link: '/workflow-desk/task',
            id: 'MT32',
          },
          {
            label: 'Schedule',
            link: '/workflow-desk/schedule',
            id: 'MS33',
          },
        ],
        id: 'MCN31',
      },
    ],
    children: PageChildren.workflowChildren(),
    id: 'MWD28',
  },
];
