import { Router, type CanActivateChildFn } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { inject } from '@angular/core';

export const authenticationGuard: CanActivateChildFn = (childRoute, state) => {
  if (inject(AuthenticationService).getFromLocal()) return true;
  else {
    inject(Router).navigateByUrl('/auth');
    return false;
  }
};
